.CouncilList {
  margin: 50px;
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular",
    "DMSerifDisplayItalic", sans-serif;
  text-align: center;
  margin-bottom: 80px;
  justify-content: center;
}

.Council {
  background: linear-gradient(to bottom, white, #d9dce6, #fcfcfc);
  justify-content: center;
}

.CouncilList h1 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 30px;
}

.CouncilCard img {
  width: 50%;
  object-fit: cover;
  object-position: center;
}

.CouncilCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-align: center;
  max-width: 700px;
  height: 700px;
  background-color: #ebe4ce;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.CouncilCard h2 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
}

.CouncilCard h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.CouncilCard p {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 1.5;
}

.CouncilCard a {
  text-decoration: none;
  color: black;
  transition: color 0.3s;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
  &:hover {
    color: #716311;
    transform: scale(1.1);
  }
}

.carousel {
  z-index: 1;
}

@media (max-width: 800px) {
  .CouncilList {
    align-items: center;
    justify-content: center;
  }

  .CouncilCard {
    max-width: 80%;
    height: 600px;
  }

  .CouncilCard h3 {
    font-size: 18px;
  }

  .CouncilCard h2 {
    font-size: 30px;
  }

  .CouncilCard p {
    font-size: 18px;
  }

  .CouncilList h1 {
    font-size: 40px;
  }
}