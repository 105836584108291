.logo {
  width: 300px;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular", "DMSerifDisplayItalic", sans-serif;
}

.descContainer {
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 20px;
  border-left: 2px solid #121619;
  padding-left: 30px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.descTitle {
  font-weight: 700;
  font-size: 35px;
}

.backButton {
  color: #121619;
  border: none;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border: #121619 3px solid;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  background-color: transparent;
  padding-left: 0;
}

.backButton:hover {
  /* background-color: #07090a; */
  /* transform: scale(1.05); */
  color: white;
  background-color: #121619;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

/* For mobile res */
@media only screen and (max-width: 768px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .descContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
  }

  .descTitle {
    font-weight: 700;
    font-size: 30px;
  }
}
