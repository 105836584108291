/* body {
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, white, #cfd7ed, #d3d3d3, #cfc49b, white);
} */

.Article {
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular", "DMSerifDisplayItalic", sans-serif;
  padding: 50px 0;
  /* text-align: center; */
  font-weight: 300;
  color: #333;
  background-color: #e5e5e5;
}

.container {
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular", "DMSerifDisplayItalic", sans-serif;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  transition: background-color 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  background-color: white; /* Keeping the box white */
}

.box:hover {
  background-color: #e0e0e0;
}

.title {
  font-size: 1.8rem;
  text-align: justify;
  line-height: 1.5;
  padding: 0px 10px;
}

.meta-data {
  font-size: 0.8rem;
  color: #666;
}

.authorDetails{
  display: flex;
  gap: 10px;
}

.summary {
  font-size: 1rem;
  line-height: 1.5;
  padding: 0px 10px;
}

.boxImageContainer {
  display: flex;
  width: 80%;
}

.boxImage{
  display: absolute;
  width: 100%;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: zoomInBboing 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  font-family: "Times New Roman", Times, serif;
}

@keyframes zoomInBboing {
  0% {
    transform: scale(0.1) translateY(0);
  }
  70% {
    transform: scale(1.2) translateY(-20px); /* Adjust bounce height and timing */
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  max-width: 900px;
  max-height: 600px;
  width: 100%;
  text-align: justify;
  z-index: 1001;
  animation: fadeIn 0.5s ease;
  line-height: 1.5;
  overflow: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-content button {
  color: #666;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #666;
  height: 30px;
  width: 60px;
  color: white;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  z-index: 1002;
}

.popup-content button:hover {
  background-color: rgb(212, 212, 212);
  color: #333;
}

@media screen and (max-width: 1000px) {
  .popup-content {
    max-width: 80%;
    max-height: 70%;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .container {
    max-width: 80%;
    align-items: center;
    justify-content: center;
  }

  .box {
    max-width: 100%;
    height: 100%;
  }

  .title {
    font-size: 1.4rem;
    text-align: justify;
    line-height: 1.5;
    padding: 0px 10px;
  }

  .summary {
    font-size: 0.7rem;
    line-height: 1.5;
    padding: 0px 10px;
  }

  .boxImageContainer {
    width: 100%;
  }
}
