.Timeline {
  background: linear-gradient(to bottom, white, #cfd7ed, #fcfcfc);
  color: black;
  position: relative;
  /* max-width: 1200px; */
  margin: 100px auto;
  /* font-family: "Poppins", sans-serif; */
  font-family: "GloockRegular", "DMSerifDisplayRegular", "BelgianoSerif2",
    "DMSerifDisplayItalic", sans-serif;
  padding: 0;
  width: 100%;
}

.Title h1 {
  font-size: 60px;
  font-weight: 300;
  margin-bottom: 50px;
  margin-top: 70px;
  text-align: center;
}

.Container {
  position: relative;
  background-color: linear-gradient(to bottom, white, #cfd7ed, #fcfcfc);
  width: 50%;
  animation: movedown 1s linear forwards;
  opacity: 0;
}

@keyframes movedown {
  0% {
    transform: translateY(-30px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.Container:nth-child(1) {
  animation-delay: 0s;
}

.Container:nth-child(2) {
  animation-delay: 1s;
}

.Container:nth-child(3) {
  animation-delay: 2s;
}

.Container:nth-child(4) {
  animation-delay: 3s;
}

.Container:nth-child(5) {
  animation-delay: 4s;
}

.Container:nth-child(6) {
  animation-delay: 5s;
}

.Container:nth-child(7) {
  animation-delay: 6s;
}

.Container:nth-child(8) {
  animation-delay: 7s;
}

.TextBox {
  border-radius: 8px;
  padding: 20px 30px;
  position: relative;
  background-color: white;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.LeftContainer {
  left: 1%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  align-items: center;
}

.RightContainer {
  left: 48%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.Container img {
  top: 35%;
  width: 40px;
  border-radius: 50%;
  right: 86px;
}

.Timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 85%;
  background: white;
  top: 11%;
  bottom: 0;
  left: 49.5%;
  margin-left: -1px;
  animation: moveline 6s linear forwards;
}

@keyframes moveline {
  0% {
    height: 0;
  }
  100% {
    height: 85%;
  }
}

.TextBox h2 {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
}

.TextBox small {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 15px;
}

.LeftArrow {
  height: 0;
  width: 0;
  position: absolute;
  top: 43%;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid white;
  right: -14px;
}

.RightArrow {
  height: 0;
  width: 0;
  position: absolute;
  top: 43%;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid white;
  left: -14px;
}

@media screen and (max-width: 1200px) {
  .Timeline {
    margin: 50px auto;
    margin-bottom: 100px;
  }
  .Timeline::after {
    left: 70px;
  }

  .TextBox {
    font-size: 12px;
    min-width: 160px;
  }
  .TextBox h2 {
    font-size: 20px;
  }
  .TextBox small {
    margin-bottom: 10px;
  }
  .LeftContainer {
    left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .RightContainer {
    left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .LeftContainer img,
  .RightContainer img {
    left: -20px;
  }
  .RightArrow,
  .LeftArrow {
    border-right: 15px solid white;
    border-left: 0;
    left: -14px;
  }
  .Title h1 {
    font-size: 45px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
