.Registration {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular",
    "DMSerifDisplayItalic", sans-serif;
  background-image: url("../assets/BG-regis2.png");
  /* filter: saturate(60%); */
  opacity: 0.8;
  gap: 100px;
}

.GuideBook {
  background-color: #ffffff;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  background-color: linear-gradient(to bottom, white, #cfd7ed, #fcfcfc);
}

.guidebookContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.GuideBook p {
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GuideBook img {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Responsive For Mobile*/
@media only screen and (max-width: 500px) {
  .GuideBook {
    width: 90%;
    padding: 20px 0px;
  }
  .guidebookContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }
  .GuideBook p {
    font-size: 25px;
    font-weight: 300;
    text-align: center;
  }
}



.GuideBook button {
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #121619;
  height: 38px;
  width: 145px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
}

.GuideBook button:hover {
  background-color: rgb(179, 183, 197);
  color: #121619;
  /* background-color: #07090a; */
  transform: scale(1.05);
}

.GuideBook button:active {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Added box-shadow on active */
}

.RegistrationForm {
  background-color: #ffffff;
  opacity: 0.9;
  display: flex;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  position: relative;
  background-color: linear-gradient(to bottom, white, #cfd7ed, #fcfcfc);
  width: auto;
  padding: 20px 100px;
  text-align: center;
}
.registrationContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

/* Responsive For Mobile*/
@media only screen and (max-width: 500px) {
  .RegistrationForm {
    width: 90%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .registrationContent {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: column;
  }

  .RegistrationForm p {
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 30px;
  }
}

.RegistrationForm p {
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 30px;
}

.RegistrationForm button {
  margin-bottom: 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #121619;
  height: 50px;
  width: 250px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
}

.RegistrationForm button:hover {
  background-color: rgb(179, 183, 197);
  color: #121619;
  /* background-color: #07090a; */
  transform: scale(1.05);
}

.RegistrationForm button:active {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Added box-shadow on active */
}
