.App {
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}



body {
  padding: 0;
  margin: 0;
}