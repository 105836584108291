.Footer {
  width: 100%;
  background-color: #121619;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular", "DMSerifDisplayItalic", sans-serif;
}

.SocMed p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SocMed svg {
  color: white;
  margin: 10px;
  margin-bottom: 0px;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.SocMed svg:hover {
  transform: scale(1.1);
  color: #ffcc00;
}

.Footer p {
  color: white;
  font-size: 14px;
}

.Copyright {
  color: white;
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
}
