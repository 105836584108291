.Navbar {
  position: sticky;
  inset: 0;
  width: 100%;
  height: 120px;
  background-color: #040f25;
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular", "DMSerifDisplayItalic", sans-serif;
}

.Navbar .LeftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
  position: relative;
  z-index: 1;
}

.Navbar #close .LeftSide {
  display: flex;
}

.Navbar #open .LeftSide {
  display: none;
}

.Navbar .LeftSide img {
  width: 120px;
}

.Navbar .LeftSide .logo-text {
  display: block;
}

.Navbar .RightSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
}

.Navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.3s, transform 0.3s;

  &:hover {
    color: #ffcc00;
    transform: scale(1.1);
  }
}

.Navbar .RightSide button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: none;
}

.Navbar .RightSide svg {
  font-size: 40px;
}

.Navbar #open {
  padding-left: 0px;
}

.Navbar #open img {
  display: none;
}

.Navbar #close img {
  display: inherit;
}

.Navbar #open a {
  width: 70px;
  margin: 5px;
}

.Navbar .LeftSide #logo-text-mobile {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .Navbar {
    width: 100%;
  }

  .Navbar .LeftSide {
    padding-left: 20px;
  }

  .Navbar .RightSide a {
    display: none;
  }

  .Navbar .RightSide button {
    display: inherit;
  }

  .Navbar #open img {
    display: block;
  }

  .Navbar #close img {
    display: block;
  }
}
