.Sponsor {
  background: linear-gradient(
    to bottom,
    white,
    #cfd7ed,
    #d3d3d3,
    #cfc49b,
    white
  );
  padding: 50px 0;
  text-align: center;
  font-family: "GloockRegular", "BelgianoSerif2", "DMSerifDisplayRegular", "DMSerifDisplayItalic", sans-serif;
  font-weight: 300;
  color: #333;
  align-items: center;
}

.Sponsor h1 {
  font-size: 50px;
  padding-top: 50px;
  font-weight: 700;
  margin-bottom: 50px;
}

@media screen and (max-width: 1100px) {
  .Sponsor h1 {
    font-size: 40px;
  }
}