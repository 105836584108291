.Home {
  width: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "DMSerifDisplayRegular", "BelgianoSerif2", "DMSerifDisplayItalic", "GloockRegular", sans-serif;
  background-image: url("../assets/BG.jpg");
  filter: saturate(60%);
  opacity: 0.8;
  position: relative;
}

.Home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(12, 12, 23);
  filter: saturate(60%);
  opacity: 0.6;
  z-index: -1;
}

.LogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Logo {
  width: 500px;
  height: auto;
  max-width: 100%;
}

.HeaderContainer {
  width: 33%;
  padding-top: 20px;
  padding-left: 50px;
  padding-bottom: 50px;
  border-radius: 20px;
  padding-right: 50px;
  /* background-color: white; */
  opacity: 0.9;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.HeaderContainer h1 {
  font-size: 100px;
  height: 60px;
  font-weight: bold;
  color: white;
}

.HeaderContainer p {
  padding: 0;
  font-size: 30px;
  font-weight: lighter;
  color: white;
}

.HeaderContainer button {
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  height: 45px;
  width: 170px;
  color: #121619;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
}

.HeaderContainer button:hover {
  background-color: gainsboro;
  /* background-color: #07090a; */
  transform: scale(1.05);
}

.HeaderContainer button:active {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Added box-shadow on active */
}

@media screen and (max-width: 2000px) {
  .HeaderContainer h1 {
    font-size: 55px;
  }
  
}

@media screen and (max-width: 1200px) {

  .Home {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  .LogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Logo {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }

  .HeaderContainer {
    border-radius: 10px;
    width: 100%;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .HeaderContainer h1 {
    font-size: 50px; 
    text-align: center;
  }

  .HeaderContainer p {
    text-align: center;
    padding: 0px 20px;
  }

  .HeaderContainer button {
    width: 180px; 
    font-size: 14px;
    text-align: center;
    display: block;
    margin: 0 auto;
    text-decoration: none !important;
  }
}