.about {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "DMSerifDisplayRegular", "GloockRegular", "BelgianoSerif2",
  "DMSerifDisplayItalic", sans-serif;
  background: linear-gradient(
    to bottom,
    white,
    #cfd7ed,
    #d3d3d3,
    #cfc49b,
    white
  );
  flex-shrink: 0;
}

.aboutTop {
  width: 100%;
  height: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutTop h1 {
  font-weight: 400;
  font-size: 70px;
  color: #040f25;
  height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.aboutTop p {
  width: 1200px;
  margin-top: 50px;
  margin: 0 auto;
  font-size: 22px;
  color: #040f25;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.aboutBottom p {
  margin: 0 auto;
  font-size: 22px;
  color: #040f25;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 10px;
}

.video-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 10px 20px;
  box-shadow: 2px 1px 3px;
  border-radius: 20px;
}

.whiteLayer{
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  border-radius: 20px;
}

.iframeContainer{
  width: 750px; 
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/9;
}
/* Responsive */
@media screen and (max-width: 1200px) {
  .iframeContainer{
    width: 80vw; 
    position: relative;
    overflow: hidden;
    aspect-ratio: 16/9;
  }
}

.videoIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: auto;
  display: block;
}

.video-caption {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 26px !important;
  color: #040f25;
  z-index: 1;
}

/* Responsive */
@media screen and (max-width: 500px) {
  .video-caption {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 18px !important;
    color: #040f25;
    z-index: 1;
  }
}

@media screen and (max-width: 1200px) {
  .aboutTop h1 {
    font-size: 50px;
  }
  
  .about {
    height: auto;
  }
  
  .aboutTop p,
  .aboutBottom p {
    width: 90%;
    font-size: 18px;
  }
  
  .video-container {
    max-width: 80%;
    margin: 0 auto;
  }
}
